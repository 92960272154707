module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_xiyodzqxwszplbvmjrhga3l6cq/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Doomsday Trainer","short_name":"Doomsday Trainer","description":" ","start_url":"/","background_color":"#343334","theme_color":"#343334","display":"standalone","icon":"src/assets/images/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e05c4a1dee1de32118eb0b3689982ab1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_dlbutvmmt5dzmoixfgu3pyxe4q/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
